import * as React from "react";
import {
  Typography,
  Box,
  TextField,
  CardContent,
  Card,
  Grid,
  Divider,
} from "@mui/material";

import { useUpsertCustomer } from "../../../Api/useUpsertCustomer";

import ContactVisitHistory from "../ContactVisitsHistory/ContactVisitsHistory";
import CardUserTags from "./CardUserTags";
import EditButton from "../../EditButton/EditButton";

export default function ContactCardDetails({
  contactData,
  handleCustomerUpsert,
}) {
  const authToken = localStorage.getItem("groomer__authToken");
  const { upsertCustomer } = useUpsertCustomer();
  const isMobile = window.innerWidth < 450;

  const [editMode, setEditMode] = React.useState(false);

  const [editName, setEditName] = React.useState(contactData.name);
  const [editPhoneNumber, setEditPhoneNumber] = React.useState(
    contactData.phoneNumber
  );
  const [editDog, setEditDog] = React.useState(contactData.dog);
  const [editTagsAsNumbers, setEditTagsAsNumbers] = React.useState("");

  const handleSave = async (tagsAsNumbers) => {
    console.log(tagsAsNumbers, editTagsAsNumbers);
    const requestBody = {
      id: contactData.id,
      name: editName,
      phoneNumber: editPhoneNumber,
      dog: editDog,
      email: contactData.email,
      tags: tagsAsNumbers ?? editTagsAsNumbers,
    };
    console.log(requestBody);

    try {
      await upsertCustomer(requestBody, "PUT");
      handleCustomerUpsert(requestBody);
    } catch (error) {
      console.error(error);
    }
  };

  const [visitHistory, setVisitHistory] = React.useState([]);

  React.useEffect(() => {
    setEditName(contactData.name);
    setEditPhoneNumber(contactData.phoneNumber);
    setEditDog(contactData.dog);

    const fetchUserVisits = async (userId) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/GetVisitsForUser/${userId}`,
        {
          method: "GET",
          headers: {
            Accept: "json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
        }
      );

      return await response.json();
    };

    const initializeData = async () => {
      setEditName(contactData.name);
      setEditPhoneNumber(contactData.phoneNumber);
      setEditDog(contactData.dog);

      if (
        contactData.tags !== undefined &&
        typeof contactData.tags !== "string"
      ) {
        console.log(typeof contactData.tags);
        setEditTagsAsNumbers(
          contactData.tags
            .map((tagItem) => {
              return String(tagItem);
            })
            .join(",")
        );
      }

      // Fetch visit history here
      const visitHistory = await fetchUserVisits(contactData.id);
      setVisitHistory(visitHistory);
    };

    initializeData();
  }, [contactData]);

  const handleTagsUpdate = (tagsData) => {
    const tagsAsNumbers = tagsData
      .filter((tagItem) => tagItem.state === true)
      .map((tagItem) => String(tagItem.key))
      .join(",");

    handleSave(tagsAsNumbers);
  };

  if (!isMobile) {
    return (
      <>
        <Box sx={{ position: "", top: 80 }}>
          <Card>
            <CardContent
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ width: 1 }}>
                <Box>
                  <Grid
                    container
                    spacing={0}
                    sx={{
                      display: "flex",
                      "& > *": {
                        my: "8px !important",
                        minHeight: "4rem",
                        display: "flex",
                        alignItems: "center",
                        px: { sm: 2 },
                      },
                    }}
                  >
                    <Grid item xs={3}>
                      <Typography variant="h5">Imię i nazwisko</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        id="name"
                        label="Imię i nazwisko"
                        variant="outlined"
                        value={editName ?? ""}
                        onChange={(e) => setEditName(e.target.value)}
                        disabled={!editMode}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="h5">Number telefonu</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        id="phoneNumber"
                        label="Numer telefonu"
                        variant="outlined"
                        value={editPhoneNumber ?? ""}
                        onChange={(e) => setEditPhoneNumber(e.target.value)}
                        disabled={!editMode}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="h5">Pies</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        id="dog"
                        label="Pies"
                        variant="outlined"
                        value={editDog ?? ""}
                        onChange={(e) => setEditDog(e.target.value)}
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Divider />
                  <CardUserTags
                    userTagsArray={contactData.tags}
                    handleTagsUpdate={handleTagsUpdate}
                  />
                </Box>
              </Box>

              <EditButton
                handleSave={handleSave}
                handleSetEditMode={setEditMode}
              />
            </CardContent>
          </Card>

          {visitHistory.length !== 0 && (
            <ContactVisitHistory customerVisits={visitHistory} />
          )}
        </Box>
      </>
    );
  }
}
