import * as React from "react";
import {
  Container,
  TextField,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Box,
  Typography,
  Divider,
} from "@mui/material";

export default function HelpPage() {
  const isMobile = window.innerWidth < process.env.REACT_APP_MOBILE_SIZE;
  const blockMinHeight = isMobile ? 'inherit' : 500;

  return (
    <Container sx={{ maxWidth: "100% !important", mt: 4, color: "black" }}>
      <Box id="log1" sx={{ '& > *': {my: 4} }}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">23.06.2024</Typography>
          <Divider sx={{ flex: 1, borderColor: "black", ml: 2 }} />
        </Box>

        <Box sx={{ display: "flex", minHeight: blockMinHeight }}>
          <Box
            component="img"
            sx={{ maxHeight: 500, display: isMobile ? 'none' : 'block' }}
            src="/helppage/23062024/kontakty1.png"
          />
          <Box sx={{ m: 2 }}>
            <Typography variant="h6">1. Edytowanie klienta/produktu</Typography>
            <Typography variant="body1">Otwierając podgląd danych klienta bądź produktu, domyślnie pola edycji są wyszarzone i wyświetlają wcześniej wpisane dane. Aby edytować wpis, należy kliknąć niebieski guzik edycji z ikonką długopisu.</Typography>
            <Typography variant="h6">2. Podgląd historii wizyt danego klienta</Typography>
            <Typography variant="body1">Każdy klient posiadający conajmniej jedną wizytę w systemie, pod swoimi danymi będzie miał widoczną sekcję swoich wizyt. Aby ją wyświetlić, należy nacisnąć na tekst "Pokaż historię wizyt".</Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", minHeight: blockMinHeight }}>
          <Box
            component="img"
            sx={{ maxHeight: 500, display: isMobile ? 'none' : 'block' }}
            src="/helppage/23062024/kontakty2.png"
          />
          <Box sx={{ m: 2 }}>
            <Typography variant="h6">3. Etykiety klientów</Typography>
            <Typography variant="body1">Do aplikacji zostały dodane etykiety klientów. Aby przypisać klientowi etykietę/cechę, należy z listy dostępnych wyborów zaznaczyć kliknięciem te, które chcemy przypisać. Kiedy wybrane etykiety będą podświetlone na żółto, należy kliknąć ostatnią z nich z ikoną dyskietki - "Zapisz"</Typography>            
          </Box>
        </Box>

        <Box sx={{ display: "flex", minHeight: blockMinHeight }}>
          <Box
            component="img"
            sx={{ maxHeight: 500, display: isMobile ? 'none' : 'block' }}
            src="/helppage/23062024/kontakty3.png"
          />
          <Box sx={{ m: 2 }}>
            <Typography variant="h6">4. Edytowanie klienta/produktu/wizyty</Typography>
            <Typography variant="body1">Domyślnie dane każdego wpisu wyświetlają się wyszarzone i niemożliwe do edytowania, aby umożliwić edycję należy kliknąć niebieski przycisk z ołówkiem. Jeżeli nie chcemy wprowadzać zmian, z rozszonego niebieskiego przycisku należy kliknąć ikonę "X", jeżeli chcemy zapisać zmiany, należy kliknąć ikonę z dyskietką.</Typography>            
          </Box>
        </Box>

        <Box sx={{ display: "flex", minHeight: blockMinHeight }}>
          <Box
            component="img"
            sx={{ maxHeight: 500, display: isMobile ? 'none' : 'block' }}
            src="/helppage/23062024/wizyty1.png"
          />
          <Box sx={{ m: 2 }}>
            <Typography variant="h6">5. Zmiana miesiąca w widoku wizyt</Typography>
            <Typography variant="body1">Aby poruszać się pomiędzy miesiącami na stronie wyświetlającej wizyty, należy klikąć guzik "Poprzedni" bądź "Następny". Pomiędzy nimi wyświetla się obecnie wybrany miesiąc. Tak jak w zakładcie klientów i produktów, wizyty również możemy edytować po naciśnięciu niebieskiego przycisku.</Typography>            
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
