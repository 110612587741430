export const useCustomers = () => {
  const authToken = localStorage.getItem("groomer__authToken");

  const upsertCustomer = async (data, httpMethod) => {
    const suffix = data.Id ? `/${data.Id}` : "";

    const url = `${process.env.REACT_APP_API_URL}/api/Customer${suffix}`;

    const requestBody = {
      id: data.id ?? null,
      name: `${data.name}`,
      phoneNumber: data.phoneNumber,
      dog: data.dog,
      email: data.email,
      visitCount: data.visitCount,
    };

    try {
      const response = await fetch(url, {
        method: httpMethod,
        headers: {
          Accept: "json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return { upsertCustomer };
};
