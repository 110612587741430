import React, { useState } from "react";

import { Typography, Box, Button, ButtonGroup } from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import RaportBarChart from "./RaportBarChart";
import TinyRaportBarChart from "./TinyRaportBarChart";

import {
  addWeeks,
  addMonths,
  addYears,
  subWeeks,
  subMonths,
  subYears,
  format,
  parse,
} from "date-fns";
import {
  getTodaysDateDMY,
  getTimeIntervalName,
} from "../../../utils/dateutils";

export default function RaportBarChartWrapper({
  dataType = "income",
  title,
  propagateWeekChange,
  data,
  timeInterval = "week",
}) {
  const isMobile = window.innerWidth < process.env.REACT_APP_MOBILE_SIZE;
  const chartHeight = isMobile ? 256 : 420;
  const [todaysDate, setTodaysDate] = useState(getTodaysDateDMY());

  const handleDateChange = (direction, type = "week") => {
    const parsedDate = parse(todaysDate, "dd-MM-yyyy", new Date());
    let newDate;

    if (direction === "increment") {
      if (type === "week") {
        newDate = addWeeks(parsedDate, 1);
      } else if (type === "month") {
        newDate = addMonths(parsedDate, 1);
      } else if (type === "year") {
        newDate = addYears(parsedDate, 1);
      }
    } else {
      if (type === "week") {
        newDate = subWeeks(parsedDate, 1);
      } else if (type === "month") {
        newDate = subMonths(parsedDate, 1);
      } else if (type === "year") {
        newDate = subYears(parsedDate, 1);
      }
    }

    const formattedDate = format(newDate, "dd-MM-yyyy");
    setTodaysDate(formattedDate);
    propagateWeekChange(formattedDate, dataType);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {!isMobile ? (
          <Typography variant="h4" sx={{ pr: 4, pl: 4, pt: 4 }}>
            {title}
          </Typography>
        ) : (
          <Typography variant="h5" sx={{ pr: 4, pl: 4, pt: 4 }}>
            {title}
          </Typography>
        )}
        {!isMobile ? (
          <ButtonGroup
            variant="text"
            aria-label="outlined primary button group"
            sx={{ pt: 4, pr: 4 }}
            disableElevation
          >
            <Button
              onClick={() => {
                handleDateChange("decrement", timeInterval);
              }}
            >
              Poprzedni
            </Button>
            <Button disabled>
              {getTimeIntervalName(timeInterval, todaysDate)}
            </Button>
            <Button
              onClick={() => {
                handleDateChange("increment", timeInterval);
              }}
            >
              Następny
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup
            size="small"
            aria-label="outlined primary button group"
            sx={{ pt: 4, pr: 4 }}
            disableElevation
          >
            <Button
              onClick={() => {
                handleDateChange("decrement", timeInterval);
              }}
            >
              <ArrowBackIosIcon />
            </Button>
            <Button
              onClick={() => {
                handleDateChange("increment", timeInterval);
              }}
            >
              <ArrowForwardIosIcon />
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <Box sx={{ p: 4, height: chartHeight }}>
        {isMobile ? (
          <TinyRaportBarChart raportingData={data} dataType={dataType} />
        ) : (
          <RaportBarChart raportingData={data} dataType={dataType} />
        )}
      </Box>
    </>
  );
}
