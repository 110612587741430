import React, { useState } from "react";
import { Container, TextField, Autocomplete, Grid, Box } from "@mui/material";
import { FixedSizeList } from "react-window";
import "../App.css";

import ContactCard from "../components/Contact/ContactCard/ContactCard";
import ContactCardDetails from "../components/Contact/ContactCard/ContactCardDetails";
import { VisitCardSkeletonScaffolding } from "../components/Visit/VisitCardSkeleton";
import ContactBar from "../components/Contact/ContactBar/ContactBar";

import { useGetEntities } from "../../src/Api/useGetEntities";

function renderRow(props) {
  const { data, index, style } = props;
  return (
    <li style={style} key={index} {...data[index].props}>
      {data[index]}
    </li>
  );
}

function VirtualizedListboxComponent(props) {
  const { children, ...other } = props;
  const items = React.Children.toArray(children);
  const itemCount = items.length;
  const itemSize = 32; // Adjust based on the size of your items

  return (
    <div {...other}>
      <FixedSizeList
        height={200} // Adjust based on the maximum height you want
        width="100%"
        itemSize={itemSize}
        itemCount={itemCount}
        itemData={items}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
}

const defaultContacts = [
  { id: 1, name: "wybierz klienta z listy", dog: "wybierz klienta z listy", visitsCount: 0, phoneNumber: "wybierz klienta z listy" },
];

export default function ContactsPage() {
  const {
    entities: contacts,
    loading,
    fetchEntities,
  } = useGetEntities([], "Customer");

  const isMobile = window.innerWidth < 450;
  const [searchListHeight, setSearchListHeight] = useState(46);

  const [searchInput, setSearchInput] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([]);

  const [highlightedContact, setHighlightedContact] = useState(
    contacts[0] ?? defaultContacts[0]
  );

  React.useEffect(() => {
    const filterContacts = (searchTerm) => {
      if (!searchTerm) {
        setFilteredContacts(contacts);
      } else {
        const filtered = contacts.filter((contact) =>
          contact.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredContacts(filtered);
      }
    };

    filterContacts(searchInput);
  }, [searchInput, contacts]);

  const handleCustomerUpsert = (requestBody) => {
    fetchEntities();
    setHighlightedContact(requestBody);
  };

  const handleHistoryExpanded = (visitsCount) => {
    // 11 rem is the height of a single ContactCard
    setSearchListHeight(searchListHeight === 46 ? (16 + visitsCount * 11) : 46);
  }

  if (loading) {
    return (
      <Box sx={{ width: 1 }}>
        <ContactBar />

        <VisitCardSkeletonScaffolding marginTop={0} />
      </Box>
    );
  } else {
    return (
      <Box>
        <ContactBar handleCustomerUpsert={handleCustomerUpsert} />

        <Container sx={{ maxWidth: "100% !important" }}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={4} sx={{ height: searchListHeight + 'rem', mt: 4, overflowY: !isMobile ? 'scroll' : 'none', borderRadius: 1 }}>
              <Autocomplete
                freeSolo
                options={contacts.map((contact) => contact.name)}
                onChange={(event, newValue) => {
                  setSearchInput(newValue || "");
                }}
                inputValue={searchInput}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "clear") {
                    setSearchInput("");
                  } else if (reason === "input") {
                    setSearchInput(newInputValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Szukaj..." variant="outlined" />
                )}
                ListboxComponent={VirtualizedListboxComponent} // Use the custom ListboxComponent
                sx={{
                  backgroundColor: "#171717",
                  borderRadius: "4px",
                  mb: 1,
                }}
              />

              {filteredContacts.map((item) => (
                <ContactCard
                  contactData={item}
                  key={item.id}
                  onContactHighlight={() => {
                    setHighlightedContact(item);

                    // TO DO: visit history for customer to be fetched here
                  }}
                />
              ))}
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              sx={{ height: "inherit", mt: 4 }}
              px={{ sm: 2 }}
            >
              <ContactCardDetails
                contactData={highlightedContact}
                handleCustomerUpsert={handleCustomerUpsert}
                historyExpandedListener={handleHistoryExpanded}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
}
