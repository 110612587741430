import React, { useState, useEffect, useRef, useCallback } from "react";

export const useGetRaportData = (
  dependencies = [],
  endpoint,
  todaysDate,
  autoFetch = true
) => {
  const authToken = localStorage.getItem("groomer__authToken");
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null);

  const [hookEndpoint, setHookEndpoint] = React.useState(endpoint);
  const [hookTodaysDate, setHookTodaysDate] = React.useState(todaysDate);

  const fetchRaportingData = useCallback(
    async (
      endpoint = hookEndpoint,
      todaysDate = hookTodaysDate,
      timeInterval = 'week'
    ) => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/${endpoint}/${todaysDate}/${timeInterval}`,
          {
            signal: abortControllerRef.current.signal,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
              Authorization: "Bearer " + authToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch ${endpoint}: ${response.statusText}`
          );
        }

        const data = await response.json();
        setEntities(data);
      } catch (err) {
        if (!abortControllerRef.current.signal.aborted) {
          setError(`Error fetching ${endpoint}: ${err.message}`);
        }
      } finally {
        if (!abortControllerRef.current.signal.aborted) {
          setLoading(false);
        }
      }
    },
    [endpoint, authToken]
  );

  useEffect(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }

    if (autoFetch) {
      fetchRaportingData();
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
      }
    };
  }, [fetchRaportingData, autoFetch, ...dependencies]);

  return { entities, loading, error, fetchRaportingData };
};
