import React from "react";
import {
  AreaChart,
  Area,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const exampleData = [
  { name: "Styczeń", uv: 4 },
  { name: "Luty", uv: 3 },
  { name: "Marczec", uv: 4 },
  { name: "Kwiecień", uv: 2 },
  { name: "Maj", uv: 1 },
  { name: "Czerwiec", uv: 3 },
];

const weekday = [
  "Poniedziałek",
  "Wtorek",
  "Środa",
  "Czwartek",
  "Piątek",
  "Sobota",
  "Niedziela",
];

export default function TinyRaportAreaChart({ raportingData, labels }) {
  const [displayData, setDisplayData] = React.useState(exampleData);
  
  // TO DO: create custom hook out of it as its being used twice
  React.useEffect(() => {
    if (raportingData !== undefined) {
      if (raportingData.length !== 0) {
        const transformedArray = raportingData.map((day) => {
          return {
            name: String(labels[day]),
            uv: day.length,
          };
        });

        setDisplayData(transformedArray);
      } else {
        setDisplayData(exampleData);
      }
    }
  }, [raportingData]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={displayData}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#d97706" stopOpacity={0.7} />
            <stop offset="95%" stopColor="#d97706" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="uv"
          fillOpacity={1}
          fill="url(#colorUv)"
          stroke="#d97706"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
