import React, { useState } from "react";
import { Container, Grid, TextField, Autocomplete, Box } from "@mui/material";

import { useGetEntities } from "../Api/useGetEntities";

import ServiceListItem from "../components/Services/ServiceItem/ServiceListItem";
import ServiceItemDetails from "../components/Services/ServiceItem/ServiceItemDetails";

import { VisitCardSkeletonScaffolding } from "../components/Visit/VisitCardSkeleton";
import ServiceBar from "../components/Services/ServiceBar/ServiceBar";

const defaultProducts = [
  { id: 2, name: "Wybierz produkt z listy", price: 0, time: 0 },
];

export default function ProductsPage() {
  const [highlightedProduct, setHighlightedProduct] = useState(
    defaultProducts[0]
  );

  const isMobile = window.innerWidth < 450;
  const [searchListHeight, setSearchListHeight] = useState(46);

  const [searchInput, setSearchInput] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  const {
    entities: products,
    loading,
    fetchEntities,
  } = useGetEntities([], "Product");

  React.useEffect(() => {
    const filterProducts = (searchTerm) => {
      if (!searchTerm) {
        setFilteredProducts(products);
      } else {
        const filtered = products.filter((contact) =>
          contact.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredProducts(filtered);
      }
    };

    filterProducts(searchInput);
  }, [searchInput, products]);

  const handleServiceUpsert = (requestBody) => {
    fetchEntities();
    setHighlightedProduct(requestBody);
  };

  if (loading) {
    return (
      <Box>
        <ServiceBar />

        <VisitCardSkeletonScaffolding marginTop={0} />
      </Box>
    );
  } else {
    return (
      <Box>
        <ServiceBar handleServiceUpsert={handleServiceUpsert} />

        <Container sx={{ color: "black", maxWidth: "100% !important" }}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                height: searchListHeight + "rem",
                mt: 4,
                overflowY: !isMobile ? "scroll" : "none",
                borderRadius: 1,
              }}
            >
              <Autocomplete
                freeSolo
                options={products.map((product) => product.name)}
                onChange={(event, newValue) => {
                  setSearchInput(newValue || "");
                }}
                inputValue={searchInput}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "clear") {
                    setSearchInput("");
                  } else if (reason === "input") {
                    setSearchInput(newInputValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Szukaj..." variant="outlined" />
                )}
                sx={{
                  backgroundColor: "#171717",
                  borderRadius: "4px",
                  mb: 1,
                  width: "100%",
                }}
              />

              {filteredProducts.map((product) => (
                <ServiceListItem
                  product={product}
                  key={product.id}
                  onProductHighlight={() => {
                    setHighlightedProduct(product);
                  }}
                />
              ))}
            </Grid>

            {highlightedProduct && (
              <Grid
                item
                xs={12}
                md={8}
                sx={{ height: "inherit", mt: 4 }}
                px={{ sm: 2 }}
              >
                <ServiceItemDetails
                  product={highlightedProduct}
                  isLoading={loading}
                  handleServiceUpsert={handleServiceUpsert}
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    );
  }
}
