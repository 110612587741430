import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DashboardPage from "./Pages/DashboardPage";
import ContactsPage from "./Pages/ContactsPage";
import RaportsPage from "./Pages/RaportsPage";
import ProductsPage from "./Pages/ProductsPage";
import VisitsPage from "./Pages/VisitsPage";
import LoginPage from "./Pages/LoginPage";
import { Auth } from "../src/components/Auth/Auth";
import PersistentDrawerMenu from "./components/PersistantDrawerMenu/PersistantDrawerMenu";
import HelpPage from "./Pages/HelpPage";

const font = ["Poppins", "sans-serif"].join(",");

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#e5e5e5",
      paper: "#171717",
    },
    primary: {
      main: '#fbbf24',
    },
  },
  typography: {
    fontFamily: font,
    color: "red",
  },
});

const router = createBrowserRouter([
  {
    path: "/panel",
    element: (
      <Auth>
        <PersistentDrawerMenu pageName="Panel">
          <DashboardPage />
        </PersistentDrawerMenu>
      </Auth>
    ),
  },
  {
    path: "/kontakty",
    element: (
      <Auth>
        <PersistentDrawerMenu pageName="Kontakty">
          <ContactsPage />
        </PersistentDrawerMenu>
      </Auth>
    ),
  },
  {
    path: "/raporty",
    element: (
      <Auth>
        <PersistentDrawerMenu pageName="Raporty">
          <RaportsPage />
        </PersistentDrawerMenu>
      </Auth>
    ),
  },
  {
    path: "/produkty-i-uslugi",
    element: (
      <Auth>
        <PersistentDrawerMenu pageName="Produkty i usługi">
          <ProductsPage />
        </PersistentDrawerMenu>
      </Auth>
    ),
  },
  {
    path: "/wizyty",
    element: (
      <Auth>
        <PersistentDrawerMenu pageName="Wizyty w tym miesiącu">
          <VisitsPage />
        </PersistentDrawerMenu>
      </Auth>
    ),
  },
  {
    path: "/pomoc",
    element: (
      <Auth>
        <PersistentDrawerMenu pageName="Pomoc">
          <HelpPage />
        </PersistentDrawerMenu>
      </Auth>
    ),
  },
  {
    path: "/",
    element: (
      <Auth>
        <LoginPage />
      </Auth>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <RouterProvider router={router} />
  </ThemeProvider>
);

reportWebVitals();
