import React from "react";
import { Card, CardContent, Box, Stack, Skeleton, Grid, Container } from "@mui/material";

export function VisitCardSkeleton({ marginBottom }) {
  return (
    <Card sx={{ mb: marginBottom ?? 1 }}>
      <CardContent>
        <Box sx={{ width: 1 }}>
          <Skeleton variant="text" sx={{ fontSize: "1.4rem" }} />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.4rem", width: "40%" }}
            />
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
}

export function VisitDetailsCardSkeleton() {
  return (
    <Card sx={{ position: "sticky", top: 130 }}>
      <CardContent>
        <Box sx={{ p: 2, width: 1 }}>
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "40%" }} />
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "10%" }} />
          </Stack>

          <Skeleton variant="text" sx={{ fontSize: "4rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "2rem", width: "30%" }} />
        </Box>
      </CardContent>
    </Card>
  );
}

export function VisitCardSkeletonScaffolding({ marginTop }) {
  return (
    <Container
      sx={{
        color: "black",
        maxWidth: "100% !important",
        height: "inherit",
        mt: 4
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ height: "inherit", mt: marginTop + 'px !important' }}>
          <VisitCardSkeleton marginBottom={2} />
          <VisitCardSkeleton />
          <VisitCardSkeleton marginBottom={2} />
          <VisitCardSkeleton />
          <VisitCardSkeleton />
        </Grid>
        <Grid item xs={12} md={8} sx={{ height: "inherit", mt: marginTop + 'px !important' }}>
          <VisitDetailsCardSkeleton />
        </Grid>
      </Grid>
    </Container>
  );
}
