import { useState, useCallback } from "react";

export const useUpsertService = () => {
  const authToken = localStorage.getItem("groomer__authToken");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const upsertService = useCallback(
    async (data, httpMethod) => {
      setLoading(true);
      setError(null);

      const suffix = data.id ? `/${data.id}` : "";
      const url = `${process.env.REACT_APP_API_URL}/api/Product${suffix}`;

      const requestBody = {
        id: data.id ?? null,
        name: `${data.name}`,
        price: data.price,
        time: data.time,
      };

      try {
        const response = await fetch(url, {
          method: httpMethod,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setResponse(result);
        return result;
      } catch (error) {
        console.error("Error:", error);
        setError(error.message);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [authToken]
  );

  return { upsertService, loading, error, response };
};
