import React from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import CalendarEventModal from "./CalendarEventModal";
import pl from "@fullcalendar/core/locales/pl";
import "./Calendar.css";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { useVisits } from "../../Api/VisitApi";
import { useGetEntities } from "../../Api/useGetEntities";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("UTC");

const defaultEvents = {
  title: "test",
  start: "2024-01-20T08:00:00+01:00",
  end: "2024-01-20T10:00:00+01:00",
};

export default function CalendarComponent() {
  const isMobile = window.innerWidth < process.env.REACT_APP_MOBILE_SIZE;

  const { entities: visits, fetchEntities: fetchVisits, loading: loadingVisits } = useGetEntities(
    [],
    "Visit"
  );
  const { upsertVisit } = useVisits();

  console.log(loadingVisits);
  

  const [modalOpen, setModalOpen] = React.useState(false);
  const [newEvent, setNewEvent] = React.useState({
    title: "",
    start: "",
    end: "",
    allDay: false,
    cleared: true,
    customer: "",
    price: 0,
    productIds: "",
  });

  const handleDateSelect = (selectInfo) => {
    setNewEvent({
      ...newEvent,
      start: dayjs.tz(selectInfo.startStr).format().toString(),
      end: dayjs.tz(selectInfo.endStr).format().toString(),
    });
    setModalOpen(true);
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
  };

  const handleSubmit = async (newEvent) => {
    const newEventToAdd = {
      id: newEvent.id ?? null,
      title: `${newEvent.customer.name} - ${newEvent?.productIds[0]?.name}`,
      start: newEvent.start,
      end: newEvent.end,
      productIds: newEvent.productIds,
      customerId: newEvent.customer?.id,
      price: newEvent.price,
      allDay: false,
      cleared: true,
    };

    await upsertVisit(
      newEventToAdd,
      newEvent.id === null || newEvent.id === undefined ? "Visit" : "UpdateVisit"
    );
    fetchVisits();

    setNewEvent({ title: "", start: "", end: "" }); // Reset the form
    setModalOpen(false);
  };

  const handleVisitDelete = () => {
    fetchVisits();
  };

  return (
    <Box position="relative">
    {loadingVisits && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            zIndex: 10,
          }}
        >
          <CircularProgress size={140} thickness={5}/>
        </Box>
      )}
      <FullCalendar
        locale={pl}
        timeZone="pl"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: isMobile ? "" : "prev,next,today",
          center: "title",
          right: isMobile
            ? "prev,today,next"
            : "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView={isMobile ? "timeGridDay" : "timeGridWeek"}
        editable={true}
        selectable={true}
        navLinks={true}
        businessHours={{
          daysOfWeek: [1, 2, 3, 4, 5, 6, 0], // Monday - Friday
          startTime: "08:00", // 8am
          endTime: "18:00", // 6pm
        }}
        slotMinTime="06:00:00"
        slotMaxTime="22:00:00"
        select={handleDateSelect}
        events={visits}
        height="48rem"
        eventClick={(event) => {
          const clickedVisit = visits.filter((visit) => {
            return visit.id.toString() === event.event.id;
          });

          setNewEvent(clickedVisit[0]);
          setModalOpen(true);
        }}
        selectMirror={true}
        snapDuration={"00:30:00"}
        eventColor="#ca8a04"
        eventTextColor="#fefce8"
      />

      <CalendarEventModal
        newEvent={newEvent}
        setNewEvent={setNewEvent}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onEventAdd={handleSubmit}
        onEventDelete={handleVisitDelete}
      />
    </Box>
  );
}
