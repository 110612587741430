import React from "react";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import { Chip } from "@mui/material";

const withDisabledByRole = (WrappedComponent) => {
  return (props) => {
    const role = localStorage.getItem("groomer__role");
    const isDisabled = role !== "admin"; // Disable if the role is not 'admin'

    return (
      <WrappedComponent {...props} disabled={isDisabled || props.disabled} />
    );
  };
};

const ButtonWithDisabledByRole = withDisabledByRole(Button);

const IconButtonWithDisabledByRole = withDisabledByRole(IconButton);

const ChipButtonWithDisabledByRole = withDisabledByRole(Chip);

export { ButtonWithDisabledByRole, IconButtonWithDisabledByRole, ChipButtonWithDisabledByRole };
