import React, { useState } from "react";
import {
  Container,
  Box,
  Divider,
  Typography,
  Grid,
  Stack,
  Button,
  ButtonGroup,
  IconButton,
} from "@mui/material";

import VisitCard from "../components/Visit/VisitCard";

import { useGetEntitiesForMonth } from "../Api/useGetEntitiesForMonth";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { getTodaysMonth, getTodaysYear } from "../utils/dateutils";
import VisitDetailsCard from "../components/Visit/VisitDetailsCard";
import { VisitCardSkeletonScaffolding } from "../components/Visit/VisitCardSkeleton";

export default function VisitsPage() {
  const isMobile = window.innerWidth < process.env.REACT_APP_MOBILE_SIZE;
  const [searchListHeight, setSearchListHeight] = useState(46);

  const todaysFetchedMonth = getTodaysMonth();
  const todaysFetchedYear = getTodaysYear();
  const [todaysMonth, setTodaysMonth] = useState(todaysFetchedMonth);
  const [todaysYear, setTodaysYear] = useState(todaysFetchedYear);

  const {
    entities: visits,
    loading,
    fetchEntities,
  } = useGetEntitiesForMonth([], todaysMonth, todaysYear, true);

  const [highlightedVisit, setHighlightedVisit] = useState();

  const handleVisitUpsert = async (requestBody) => {
    const eventDate = new Date(requestBody.start);
    // check in december if it not fails!
    await fetchEntities(eventDate.getMonth() + 1, eventDate.getFullYear());

    setHighlightedVisit(requestBody);
  };

  if (loading) {
    return <VisitCardSkeletonScaffolding marginTop={52} />;
  } else {
    return (
      <Container
        sx={{
          color: "black",
          maxWidth: "100% !important",
          height: "inherit",
          pt: 4,
        }}
      >
        <Stack direction="row" justifyContent="flex-end">
          <Box sx={{ backgroundColor: "#171717", borderRadius: 4 }}>
            {!isMobile ? (
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                disableElevation
              >
                <Button
                  onClick={() => {
                    let nextMonth = todaysMonth - 1 <= 0 ? 12 : todaysMonth - 1;
                    setTodaysMonth(nextMonth);
                    setTodaysYear(
                      nextMonth === 12 ? todaysYear - 1 : todaysYear
                    );
                  }}
                >
                  Poprzedni
                </Button>
                <Button variant="contained" disabled>
                  {todaysMonth}/{todaysYear}
                </Button>
                <Button
                  onClick={() => {
                    let nextMonth =
                      parseInt(todaysMonth) + 1 > 12
                        ? 1
                        : parseInt(todaysMonth) + 1;
                    setTodaysMonth(nextMonth);
                    let nextYear =
                      nextMonth === 1 ? parseInt(todaysYear) + 1 : todaysYear;
                    setTodaysYear(nextYear);
                  }}
                >
                  Następny
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup
                sx={{
                  width: 100,
                  justifyContent: "space-between",
                  "& > *": { width: 1 / 2 },
                }}
                aria-label="outlined primary button group"
              >
                <IconButton
                  onClick={() => {
                    let nextMonth = todaysMonth - 1 <= 0 ? 12 : todaysMonth - 1;
                    setTodaysMonth(nextMonth);
                    setTodaysYear(
                      nextMonth === 12 ? todaysYear - 1 : todaysYear
                    );
                  }}
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    let nextMonth =
                      parseInt(todaysMonth) + 1 > 12
                        ? 1
                        : parseInt(todaysMonth) + 1;
                    setTodaysMonth(nextMonth);
                    let nextYear =
                      nextMonth === 1 ? parseInt(todaysYear) + 1 : todaysYear;
                    setTodaysYear(nextYear);
                  }}
                  sx={{
                    borderLeft: "1px solid silver",
                    borderRadius: 0,
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </ButtonGroup>
            )}
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              height: searchListHeight + "rem",
              mt: !isMobile ? 4 : 0,
              overflowY: !isMobile ? "scroll" : "none",
              borderRadius: 1,
            }}
          >
            {visits.map((item, key) => {
              return (
                <Box sx={{ maxWidth: "100% !important" }} key={key}>
                  <Typography variant="h6" fontWeight={500}>{item.Date}</Typography>
                  <Divider />
                  {item.Visits.map((visit) => {
                    return (
                      <VisitCard
                        visitData={visit}
                        key={visit.id}
                        isMobile={isMobile}
                        onVisitHighlight={() => setHighlightedVisit(visit)}
                      />
                    );
                  })}
                </Box>
              );
            })}
          </Grid>

          <Grid item xs={12} md={8} sx={{ height: "inherit", mt: 2 }}>
            <VisitDetailsCard
              visitData={highlightedVisit}
              isMobile={isMobile}
              isLoading={loading}
              handleVisitUpsert={handleVisitUpsert}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}
