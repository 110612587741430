import * as React from "react";
import { Chip, Stack } from "@mui/material";
import { ChipButtonWithDisabledByRole } from "../../ButtonDisabledByRole/withDisabledByRole";

import SaveAsIcon from "@mui/icons-material/SaveAs";

export default function CardUserTags({ userTagsArray, handleTagsUpdate }) {
  const [tagsData, setTagsData] = React.useState([
    { key: 0, label: "Gryzący", state: false },
    { key: 1, label: "Alergik", state: false },
    { key: 2, label: "Stary", state: false },
    { key: 3, label: "Sczenię", state: false },
    { key: 4, label: "Wystraszony", state: false },
    { key: 5, label: "Chory", state: false },
  ]);

  // console.log(userTagsArray);

  React.useEffect(() => {
    if (userTagsArray?.length > 0) {
      const updatedTags = tagsData.map((tag) => {
        const tagKeyAsString = tag.key.toString();
        return userTagsArray.includes(tagKeyAsString)
          ? { ...tag, state: true }
          : { ...tag, state: false };
      });

      setTagsData(updatedTags);
    }
  }, [userTagsArray]);

  return (
    <Stack direction={"row"} flexWrap={"wrap"} sx={{ mt: 1 }}>
      {tagsData.map((data) => {
        let color = data.state === true ? "primary" : undefined;

        return (
          <Chip
            label={data.label}
            sx={{ mx: 0.5, my: 0.5, "&:hover": { cursor: "pointer" } }}
            color={color}
            key={data.key}
            onClick={() => {
              tagsData[data.key].state = !tagsData[data.key].state;
              setTagsData([...tagsData]);
            }}
          />
        );
      })}

      <ChipButtonWithDisabledByRole
        icon={<SaveAsIcon />}
        label="Zapisz"
        sx={{ mx: 0.5, my: 0.5 }}
        onClick={() => {
          // console.log(tagsData);
          handleTagsUpdate(tagsData);
        }}
      />
    </Stack>
  );
}
