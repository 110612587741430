import { useState, useEffect, useRef, useCallback } from "react";

export const useGetEntitiesForMonth = (dependencies = [], todaysMonth, todaysYear, autoFetch = true) => {
  const authToken = localStorage.getItem("groomer__authToken");
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null);

  const fetchEntities = useCallback(async (todayM = todaysMonth, todayY = todaysYear) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/GetVisitsForMonth/${todayY}/${todayM}?leaveEmpty=true`,
        {
          signal: abortControllerRef.current.signal,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
            Authorization: "Bearer " + authToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch ${todayM}: ${response.statusText}`);
      }

      const data = await response.json();
      setEntities(data);
      return data;
    } catch (err) {
      if (!abortControllerRef.current.signal.aborted) {
        setError(`Error fetching ${todayM}: ${err.message}`);
      }
    } finally {
      if (!abortControllerRef.current.signal.aborted) {
        setLoading(false);
      }
    }
  }, [todaysMonth, authToken]);

  useEffect(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }

    if (autoFetch) {
      fetchEntities();
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
      }
    };
  }, [fetchEntities, autoFetch, ...dependencies]);

  return { entities, loading, error, fetchEntities };
};
