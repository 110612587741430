export const useVisits = () => {
  const authToken = localStorage.getItem("groomer__authToken");

  const upsertVisit = async (data, endpoint) => {
    const suffix = data.id ? `/${data.id}` : "";

    console.log(data);
    const url = `${process.env.REACT_APP_API_URL}/api/${endpoint}${suffix}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
            "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const deleteVisit = async (visitId) => {
    const url = `${process.env.REACT_APP_API_URL}/api/Visit/${visitId}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text-plain, */*",
          Authorization: "Bearer " + authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return { upsertVisit, deleteVisit };
};
