import * as React from "react";
import {
  Typography,
  Box,
  TextField,
  CardContent,
  Card,
  Grid,
} from "@mui/material";

import { useUpsertService } from "../../../Api/useUpsertService";

import { VisitDetailsCardSkeleton } from "../../Visit/VisitCardSkeleton";
import EditButton from "../../EditButton/EditButton";

export default function ServiceItemDetails({
  product,
  isLoading,
  handleServiceUpsert,
}) {
  const { upsertService } = useUpsertService();
  const isMobile = window.innerWidth < process.env.REACT_APP_MOBILE_SIZE;

  const [editMode, setEditMode] = React.useState(false);

  const [editName, setEditName] = React.useState("");
  const [editPrice, setEditPrice] = React.useState(0);
  const [editTime, setEditTime] = React.useState(0);

  const handleSave = async () => {
    const requestBody = {
      id: product.id,
      name: editName,
      price: editPrice,
      time: editTime,
    };

    try {
      await upsertService(requestBody, "PUT");

      handleServiceUpsert(requestBody);
    } catch (error) {}
  };

  React.useEffect(() => {
    setEditName(product.name);
    setEditPrice(product.price);
    setEditTime(product.time);
  }, [product]);

  if (!isMobile) {
    if (!isLoading) {
      return (
        <Card sx={{ position: "sticky", top: 80 }}>
          <CardContent
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box sx={{ width: 1 }}>
              <Box>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    display: "flex",
                    "& > *": {
                      my: "8px !important",
                      minHeight: "4rem",
                      display: "flex",
                      alignItems: "center",
                      px: { sm: 2 },
                    },
                  }}
                >
                  <Grid item xs={3}>
                    <Typography variant="h5">Nazwa</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      id="name"
                      label="Nazwa"
                      variant="outlined"
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                      disabled={!editMode}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="h5">Cena</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      id="price"
                      label="Cena"
                      variant="outlined"
                      value={editPrice / 100}
                      onChange={(e) => setEditPrice(e.target.value * 100)}
                      disabled={!editMode}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="h5">Czas trwania</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      id="time"
                      label="Czas trwania (w godzinach)"
                      variant="outlined"
                      value={editTime / 60}
                      onChange={(e) => setEditTime(e.target.value * 60)}
                      disabled={!editMode}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <EditButton
              handleSave={handleSave}
              handleSetEditMode={setEditMode}
            />
          </CardContent>
        </Card>
      );
    } else {
      return <VisitDetailsCardSkeleton />;
    }
  }
}
