import React from "react";
import { Box, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const exampleData2 = {
  confirmedIncome: [0, 0, 0, 0, 0, 0, 0],
  unconfirmedIncome: [0, 0, 0, 0, 0, 300, 300],
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          borderRadius: 2,
          padding: 2,
          backgroundColor: "grey",
        }}
      >
        {label}
        {payload.map((item, key) => (
          <Box display={"flex"} alignItems={"center"} key={key}>
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: item.color,
                mr: 1,
                borderRadius: 1,
              }}
            ></Box>
            <Typography className="label">{`${item.dataKey} : ${item.value}`}</Typography>
          </Box>
        ))}
      </Box>
    );
  }

  return null;
};

export default function RaportBarChart({ raportingData, dataType }) {
  const [displayData, setDisplayData] = React.useState(exampleData2);
  // TO DO: when parent component does not pass prop, it breaks

  React.useEffect(() => {
    if (raportingData.data !== undefined) {            
      var transformedArray = [];
      if (Object.keys(raportingData.data).length !== 0) {
        if (Object.keys(raportingData.data).includes("confirmedIncome")) {
          transformedArray = raportingData.data.confirmedIncome.map(
            (element, index) => {
              return {
                name: String(raportingData.labels[index]),
                należności: raportingData.data.confirmedIncome[index],
                nieopłacone: raportingData.data.unconfirmedIncome[index],
              };
            }
          );
        }

        if (Object.keys(raportingData.data).includes("workedHours")) {
          transformedArray = raportingData.data.workedHours.map((element, index) => {
            return {
              name: String(raportingData.labels[index]),
              godziny: raportingData.data.workedHours[index],
            };
          });
        }

        setDisplayData(transformedArray);
      }
    }
  }, [raportingData]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={displayData}
        margin={{
          right: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 9" vertical={false} />
        <XAxis
          dataKey="name"
          stroke={"silver"}
          tickFormatter={(value) => value.slice(0, 3) + "."}
          tickMargin={10}
        />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        {dataType === "income" ? (
          <>
            <Bar
              dataKey="należności"
              fill="#d97706"
              activeBar={<Rectangle fill="#d97706" />}
              radius={4}
              barSize={30}
            />
            <Bar
              dataKey="nieopłacone"
              fill="#fbbf24"
              activeBar={<Rectangle fill="#fbbf24" />}
              radius={4}
              barSize={30}
            />
          </>
        ) : (
          ""
        )}
        {dataType === "workedHours" ? (
          <Bar
            dataKey="godziny"
            fill="#d97706"
            activeBar={<Rectangle fill="#d97706" />}
            radius={4}
            barSize={30}
          />
        ) : (
          ""
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}
