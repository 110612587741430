import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Chip,
  Stack,
  Grid,
  Collapse,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

import { ButtonWithDisabledByRole } from "../ButtonDisabledByRole/withDisabledByRole";

import {
  LocalizationProvider,
  DatePicker,
  DesktopTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useCustomers } from "../../Api/ContactApi";
import { useVisits } from "../../Api/VisitApi";
import { useGetEntities } from "../../Api/useGetEntities";

import SaveIcon from "@mui/icons-material/Save";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Warsaw");

const mobileModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "24rem",
  bgcolor: "background.paper",
  p: 4,
  boxShadow: "8px 8px 24px 0px rgba(0, 0, 0, 1)",
  borderRadius: "8px",
  "& > *": { my: "8px !important" },
};

const desktopModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "62rem",
  bgcolor: "background.paper",
  p: 4,
  boxShadow: "8px 8px 24px 0px rgba(0, 0, 0, 1)",
  borderRadius: "8px",
  "& > *": { my: "8px !important" },
};

export default function CalendarEventModal({
  newEvent,
  setNewEvent,
  modalOpen,
  setModalOpen,
  onEventAdd,
  onEventDelete,
}) {
  const { upsertCustomer } = useCustomers();
  const { deleteVisit } = useVisits();

  const { entities: products } = useGetEntities([], "Product");
  const { entities: customers, fetchEntities: fetchCustomers } = useGetEntities(
    [],
    "Customer"
  );

  const isMobile = window.innerWidth < 450;

  const [selectedProducts, setSelectedProducts] = React.useState(products);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);

  const [editName, setEditName] = React.useState("");
  const [editDog, setEditDog] = React.useState("");
  const [editPhoneNumber, setEditPhoneNumber] = React.useState("");
  const [visitPrice, setVisitPrice] = React.useState("0");

  const [requestLoading, setRequestLoading] = React.useState(false);
  const [customerRequestError, setCustomerRequestError] = React.useState(false);

  const [extendedAddCustomerModal, setExtendedAddCustomerModal] =
    React.useState(false);

  const handleCustomerSave = async () => {
    const requestBody = {
      name: editName,
      lastName: "",
      dog: editDog,
      email: "",
      phoneNumber: editPhoneNumber,
      visitCount: 0,
    };

    setRequestLoading(true);

    try {
      const response = await upsertCustomer(requestBody, "POST");

      // TO DO: autocomplete requires different format of data
      if (response !== null && response !== undefined) {
        setSelectedCustomer(response);
        setCustomerRequestError(false);

        fetchCustomers();
        setExtendedAddCustomerModal(false);
        setEditDog("");
        setEditName("");
        setEditPhoneNumber("");
      } else {
        setCustomerRequestError(true);
      }
    } catch (error) {
      setCustomerRequestError(true);
      console.error(error);
    }

    setRequestLoading(false);
  };

  const handleAddClick = () => {
    newEvent.productIds = selectedProducts;
    newEvent.customer = selectedCustomer;
    newEvent.price = visitPrice;

    onEventAdd(newEvent);
    setModalOpen(false);
  };

  const handleDelete = async (visitId) => {
    await deleteVisit(visitId);
    onEventDelete();

    setModalOpen(false);
  };

  React.useEffect(() => {
    setSelectedProducts(
      Array.isArray(newEvent.products) ? newEvent.products : []
    );
    setVisitPrice(newEvent.price || "0");
    setSelectedCustomer(newEvent.customer ? newEvent.customer : null);
  }, [newEvent]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
        setNewEvent({ title: "", start: "", end: "" });
      }}
    >
      <Box sx={isMobile ? mobileModalStyle : desktopModalStyle}>
        {newEvent.id === undefined ? (
          <Typography variant="h5" component="h5">
            Dodaj nową wizytę
          </Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" component="h5">
              Edytuj wizytę
            </Typography>
            <ButtonWithDisabledByRole
              variant="outlined"
              size="small"
              color="error"
              onClick={() => handleDelete(newEvent.id)}
            >
              Usuń wizytę
              <DeleteIcon />
            </ButtonWithDisabledByRole>
          </Box>
        )}

        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={newEvent.id === undefined ? 8 : 12}
            sx={{ pt: "8px !important" }}
          >
            <Autocomplete
              options={customers}
              fullWidth
              value={selectedCustomer}
              getOptionLabel={(option) =>
                `${option.id} ${option.name} - ${option.phoneNumber}`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Klient"
                  placeholder="Wybierz"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((selectedCustomer, index) => (
                  <Chip
                    label={
                      selectedCustomer.name + " " + selectedCustomer.phoneNumber
                    }
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Typography variant="body1">{option.name}</Typography>
                  <Typography variant="body1" color="textSecondary">
                    &nbsp;{option.phoneNumber}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    &nbsp;{option.dog}
                  </Typography>
                </Box>
              )}
              disabled={extendedAddCustomerModal}
            />
          </Grid>

          <Grid item xs={12} md={4} sx={{ pt: "8px !important" }}>
            {extendedAddCustomerModal ? (
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() =>
                  setExtendedAddCustomerModal(!extendedAddCustomerModal)
                }
                sx={{ mt: isMobile ? "4px !important" : "", height: 1 }}
              >
                Przerwij <CancelIcon sx={{ ml: 1 }} />
              </Button>
            ) : (
              newEvent.id === undefined && (
                <ButtonWithDisabledByRole
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={() =>
                    setExtendedAddCustomerModal(!extendedAddCustomerModal)
                  }
                  sx={{ height: 1 }}
                >
                  Dodaj nowego klienta
                  <PersonAddIcon sx={{ ml: 1 }} />
                </ButtonWithDisabledByRole>
              )
            )}
          </Grid>
        </Grid>

        <Collapse in={extendedAddCustomerModal}>
          <Grid container spacing={0} /*sx={{ mt: 1 }}*/>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <TextField
                  label="Imię i nazwisko"
                  fullWidth
                  sx={{
                    mt: isMobile ? "8px !important" : "",
                  }}
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                  required
                  error={customerRequestError}
                />

                <TextField
                  label="Pies"
                  fullWidth
                  value={editDog}
                  onChange={(e) => setEditDog(e.target.value)}
                  required
                  error={customerRequestError}
                />

                <TextField
                  label="Numer telefonu"
                  fullWidth
                  value={editPhoneNumber}
                  onChange={(e) => setEditPhoneNumber(e.target.value)}
                  required
                  error={customerRequestError}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              {requestLoading === false ? (
                <Button
                  variant="contained"
                  color="success"
                  endIcon={<PersonAddIcon />}
                  fullWidth
                  sx={{ height: 1 }}
                  onClick={() => handleCustomerSave()}
                >
                  zapisz klienta
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  sx={{ height: 1 }}
                  disabled={requestLoading}
                >
                  Dodaj klienta
                  <CircularProgress color="success" size={24} />
                </Button>
              )}
            </Grid>
          </Grid>
        </Collapse>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} sx={{ pt: "4px !important" }}>
            <Autocomplete
              multiple
              options={products}
              fullWidth
              getOptionLabel={(product) =>
                `${product.name} - ${product.price / 100}`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={selectedProducts}
              onChange={(event, newValue) => {
                setSelectedProducts(newValue);

                let newPriceValue = 0;
                newValue.forEach((product) => {
                  newPriceValue += parseInt(product.price, 10);
                });

                setVisitPrice(newPriceValue.toString());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Usługi"
                  placeholder="Wybierz"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((selectedProducts, index) => (
                  <Chip
                    label={selectedProducts.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sx={{ pt: isMobile ? "16px !important" : "16px !important" }}
          >
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-amount">
                Amount
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">PLN</InputAdornment>
                }
                label="Cena"
                value={(visitPrice / 100).toFixed(2)}
                onChange={(event) => {
                  const newValue = event.target.value;
                  const valueInCents = Math.round(parseFloat(newValue) * 100);
                  setVisitPrice(valueInCents);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            defaultValue={dayjs(newEvent.start).tz("Europe/Warsaw", true)}
            label="Data wizyty"
            sx={{ width: 1 }}
            onChange={(newStartDate) => {
              const duration = dayjs(newEvent.end).diff(dayjs(newEvent.start));
              const updatedStart = dayjs(newStartDate)
                .hour(dayjs(newEvent.start).hour())
                .minute(dayjs(newEvent.start).minute());
              const updatedEnd = updatedStart.add(duration, "millisecond");

              setNewEvent({
                ...newEvent,
                start: updatedStart.toISOString(),
                end: updatedEnd.toISOString(),
              });
            }}
          />

          <DesktopTimePicker
            defaultValue={dayjs(newEvent.start).utc()}
            sx={{ width: 1 }}
            label="Godzina rozpoczęcia"
            onChange={(newEndDate) => {
              const duration = dayjs(newEvent.end).diff(dayjs(newEvent.start));
              const updatedEnd = dayjs(newEndDate).add(duration, "millisecond");

              setNewEvent({
                ...newEvent,
                start: newEndDate.toISOString(),
                end: updatedEnd.toISOString(),
              });
            }}
          />

          <DesktopTimePicker
            value={dayjs(newEvent.end).utc()}
            sx={{ width: 1 }}
            label="Godzina zakończenia"
            onChange={(newEndTime) => {
              const updatedEnd = dayjs(newEvent.end)
                .hour(newEndTime.hour())
                .minute(newEndTime.minute());
              setNewEvent({ ...newEvent, end: updatedEnd.toISOString() });
            }}
            readOnly
          />
        </LocalizationProvider>

        <ButtonWithDisabledByRole
          variant="contained"
          endIcon={<SaveIcon />}
          onClick={handleAddClick}
          fullWidth
        >
          Zapisz Wizytę
        </ButtonWithDisabledByRole>
      </Box>
    </Modal>
  );
}
