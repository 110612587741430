export const useProducts = () => {
  const authToken = localStorage.getItem("groomer__authToken");

  const upsertProduct = async (data, httpMethod) => {
    const url = `${process.env.REACT_APP_API_URL}/api/Product/${data.Id}`;

    const requestBody = {
      Id: data.Id,
      Name: data.Name,
      Price: data.Price,
      Time: data.Time,
    };

    try {
      const response = await fetch(url, {
        method: httpMethod,
        headers: {
          ContentType: "application/json",
          Accept: "application/json, text-plain, */*",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return { upsertProduct };
};
