import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  CardActions,
  IconButton,
  Collapse,
  Divider,
} from "@mui/material";

import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function VisitCard({ visitData, isMobile, onVisitHighlight }) {
  const authToken = localStorage.getItem("groomer__authToken");
  const [visit, setVisit] = React.useState(visitData);
  const [expanded, setExpanded] = React.useState(false);

  const [paymentCleared, setPaymentCleared] = React.useState(
    visitData.paymentCleared
  );

  const handleSubmit = async () => {
    const isCleared =
      paymentCleared === false || paymentCleared === undefined ? true : false;
    setPaymentCleared(isCleared);

    if (visitData.title) {
      const newEventToAdd = {
        id: visitData.id,
        start: visitData.start,
        end: visitData.end,
        productIds: visitData.products,
        paymentCleared: isCleared,
      };

      const url = `${process.env.REACT_APP_API_URL}/api/Visit/${visit.id}`;

      try {
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
          body: JSON.stringify(newEventToAdd),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      alert("Please enter a title for the event"); // Basic validation
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    setVisit(visitData);
  }, [visitData]);

  if (isMobile) {
    return (
      <Card sx={{ my: 1 }}>
        <CardContent
          sx={{
            pb: "16px !important",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <Typography variant="h6" sx={{ mr: 1 }}>
              {visit.title}
            </Typography>
          </Box>
          <Box>
            <CardActions disableSpacing>
              <IconButton
                sx={{
                  transform: !expanded ? "rotate(0deg)" : "rotate(180deg)",
                }}
                onClick={handleExpandClick}
                aria-label="expand"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
          </Box>
        </CardContent>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ "& > *": { my: "8px !important" } }}>
            <Divider />
            <FormControlLabel
              value="rozliczone"
              control={
                <Checkbox
                  checkedIcon={<PriceCheckIcon />}
                  color="success"
                  checked={paymentCleared ?? visitData.paymentCleared}
                />
              }
              label="Rozliczone"
              labelPlacement="start"
              onClick={handleSubmit}
            />
          </CardContent>
        </Collapse>
      </Card>
    );
  } else {
    return (
      <Card sx={{ my: 1, "&:hover": { cursor: "pointer" } }}>
        <CardContent
          onClick={() => {
            onVisitHighlight(visitData);
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} md={12}>
              <Typography variant="h6">{visit.title}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}
