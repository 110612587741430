// LoginPage.js
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Box,
  Container,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const cardContentStyle = {
  display: "flex",
  flexDirection: "column",
  pb: "16px !important",
};

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const isMobile = window.innerWidth < 450;

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/Login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: username, password: password }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          window.localStorage.setItem("groomer__authToken", data.token);
          window.localStorage.setItem("groomer__role", data.role);
          navigate("/panel");
        });
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  if (!isMobile) {
    return (
      <Grid container>
        <Grid
          xs={12}
          sm={6}
          sx={{
            position: "relative",
            zIndex: { xs: 2, sm: "auto" },
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "darkgray",
            color: "black",
          }}
          item
        >
          <Card sx={{ width: "26rem", backgroundColor: "white" }}>
            <CardContent sx={{ boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)" }}>
              <form onSubmit={handleLogin} style={cardContentStyle}>
                <img src="/logo_duze_transp.png" alt="logo baner" />
                <TextField
                  id="login"
                  label="Login"
                  type="text"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                  color="warning"
                  sx={{ my: 1, backgroundColor: "#171717", borderRadius: 1 }}
                />
                <TextField
                  id="password"
                  label="Hasło"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  color="warning"
                  sx={{ my: 1, backgroundColor: "#171717", borderRadius: 1 }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ my: 1, color: "white" }}
                >
                  Login
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          xs={12}
          sm={6}
          sx={{
            position: "relative",
            height: "100vh",
            display: "flex",
            justifyContent: "right",
            alignItems: "end",
          }}
          item
        >
          <img style={{ width: 468 }} src="/beach_day.svg" alt="beach day" />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Box sx={{ height: "100vh" }}>
        <Container
          sx={{
            position: "relative",
            zIndex: 10,
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Card
            sx={{
              width: "26rem",
              overflow: "hidden",
              backgroundColor: "white",
            }}
          >
            <CardContent>
              <form onClick={handleLogin} style={cardContentStyle}>
                <img src="/logo_duze_transp.png" alt="logo baner" />
                <TextField
                  id="login"
                  label="Login"
                  type="text"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                  color="warning"
                  sx={{ my: 1, backgroundColor: "#171717", borderRadius: 1 }}
                />
                <TextField
                  id="password"
                  label="Hasło"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  color="warning"
                  sx={{ my: 1, backgroundColor: "#171717", borderRadius: 1 }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ my: 1, color: "white", bgcolor: "warning.light" }}
                >
                  Login
                </Button>
              </form>
            </CardContent>
          </Card>
        </Container>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            display: "flex",
            justifyContent: "right",
            alignItems: "end",
          }}
        >
          <img style={{ width: 336 }} src="/beach_day.svg" alt="beach day" />
        </Box>
      </Box>
    );
  }
};

LoginPage.componentName = "LoginPage";

export default LoginPage;
