import { Container, Grid, Card } from "@mui/material";

import PillComponent from "../components/PillComponent/PillComponent";
import CalendarComponent from "../components/CalendarComponent/CalendarComponent";

export default function DashboardPage() {
  const isMobile = window.innerWidth < process.env.REACT_APP_MOBILE_SIZE;

  return (
    <Container sx={{ maxWidth: "100% !important", pt: 4, pb:4 }}>
      <Grid container spacing={1} sx={{ /*pl: 4, pr: 4*/ }}>
        {/* <PillComponent visible={true} when={"Dziś"} />
        <PillComponent visible={!isMobile} when={"Jutro"} />
        <PillComponent visible={!isMobile} when={"Ten tydzień"} /> */}

        <Grid
          item
          xs={12}
          md={12}
          sx={{ height: "inherit", mt: 1 }}
        >
          <Card sx={{ md: { minWidth: 275 }, sm: { minWidth: 275 } }}>
            <CalendarComponent />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

DashboardPage.componentName = 'DashboardPage';
