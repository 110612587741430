import {
  format,
  parse,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { pl } from "date-fns/locale";

export function getTodaysDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function getTodaysDateDMY() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
}

export function getTodaysYear() {
  const today = new Date();
  const year = today.getFullYear();
  return `${year}`;
}

export function getTodaysMonth() {
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  return month;
}

export function getWeekNumber(date = null) {
  let currentDate = date ? new Date(date) : new Date();

  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);

  const dayOfWeek = startOfYear.getDay();
  const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  const startOfISOYear = new Date(startOfYear.setDate(1 + diff));

  const daysSinceStartOfISOYear = Math.floor(
    (currentDate - startOfISOYear) / (24 * 60 * 60 * 1000)
  );

  const isoWeekNumber = Math.ceil((daysSinceStartOfISOYear + 1) / 7);
  return isoWeekNumber;
}

export const getTimeIntervalName = (timeInterval, todaysDate) => {
  const parsedDate = parse(todaysDate, "dd-MM-yyyy", new Date());

  switch (timeInterval) {
    case "month": {
      const monthName = format(parsedDate, "LLLL", { locale: pl });
      return `Miesiąc ${monthName}`;
    }
    case "year": {
      const year = format(parsedDate, "yyyy");
      return `Rok ${year}`;
    }
    case "week":
    default: {
      const weekStart = format(
        startOfWeek(parsedDate, { weekStartsOn: 1 }),
        "dd-MM-yyyy"
      );
      const weekEnd = format(
        endOfWeek(parsedDate, { weekStartsOn: 1 }),
        "dd-MM-yyyy"
      );
      return `(${weekStart} - ${weekEnd})`;
    }
  }
};
