import React from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Chip,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Divider,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { VisitDetailsCardSkeleton } from "./VisitCardSkeleton";
import EditButton from "../EditButton/EditButton";
import { useUpsertVisit } from "../../Api/useUpsertVisit";

const defaultVisit = {
  id: 3,
  title: "Wybierz wizytę z listy",
  dog: "Wybierz wizytę z listy",
  price: 10000,
  products: [
    { id: 1, name: "Wybierz wizytę z listy" },
  ],
  cleared: true,
};

export default function VisitDetailsCard({
  visitData = defaultVisit,
  isMobile,
  isLoading,
  handleVisitUpsert
}) {
  const { upsertVisit } = useUpsertVisit();
  const [paymentCleared, setPaymentCleared] = React.useState(visitData.cleared);
  const [comment, setComment] = React.useState(visitData.comment);
  const [price, setPrice] = React.useState(visitData.price / 100);
  const [editMode, setEditMode] = React.useState(false);

  React.useEffect(() => {
    setPaymentCleared(visitData.cleared);
    setComment(visitData.comment);
  }, [visitData]);

  React.useEffect(() => {
    if (!editMode) {
      setPrice(visitData.price / 100);
    }
  }, [editMode, visitData.price]);

  const handleSave = async () => {
    if (visitData.title) {
      const requestBody = {
        title: visitData.title,
        id: visitData.id,
        start: visitData.start,
        end: visitData.end,
        products: visitData.products,
        paymentCleared: paymentCleared,
        comment: comment,
        price: price * 100,
      };

      try {
        await upsertVisit(requestBody, "PUT");
        handleVisitUpsert(requestBody);
      } catch (error) {
        console.error(error);
      }
    }
  };

  if (!isMobile) {
    if (isLoading) {
      return <VisitDetailsCardSkeleton />;
    } else {
      return (
        <Card sx={{ position: "sticky", top: 130 }}>
          <CardContent>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: 1, "& > *": { my: 1 } }}>
                <Typography variant="h5">{visitData.title}</Typography>
                <Chip
                  label={visitData?.products[0]?.name ?? ""}
                  size="small"
                  sx={{ mt: 1 }}
                />

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel htmlFor="price">Cena</InputLabel>
                  <OutlinedInput
                    id="price"
                    startAdornment={
                      <InputAdornment position="start">PLN</InputAdornment>
                    }
                    label="Cena"
                    value={price}
                    disabled={!editMode}
                    type="number"
                    onChange={(event) => {
                      const updatedPrice = event.target.value; // Convert back to cents
                      setPrice(updatedPrice);
                    }}
                  />
                </FormControl>

                <TextField
                  id="outlined-textarea"
                  label="Miejsce na twoją notatkę."
                  placeholder="Miejsce na twoją notatkę. Możesz tutaj wpisać coś o wizycie. To pole jest opcjonalne."
                  multiline
                  fullWidth
                  value={comment ?? ""}
                  onChange={(e) => setComment(e.target.value)}
                  sx={{ fieldSizing: "content", mt: 1 }}
                  disabled={!editMode}
                />

                <Divider />

                <FormControl fullWidth>
                  <FormControlLabel
                    value="start"
                    control={<Checkbox checkedIcon={<CloseIcon />} />}
                    label="Zaznacz jeżeli wizyta się nie odbyła."
                    labelPlacement="end"
                    disabled={!editMode}
                    onChange={(event) => {
                      setPaymentCleared(!event.target.checked);
                    }}
                    checked={!paymentCleared ?? false}
                  />
                </FormControl>
              </Box>

              <EditButton
                handleSave={handleSave}
                handleSetEditMode={setEditMode}
              />
            </Box>
          </CardContent>
        </Card>
      );
    }
  }
}
