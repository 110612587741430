import * as React from "react";
import {
  Card,
  Box,
  CardActions,
  CardContent,
  Typography,
  Collapse,
  IconButton,
  Divider,
  TextField,
  Stack,
  Button,
} from "@mui/material";

import { useCustomers } from "../../../Api/ContactApi";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

export default function ContactCard({ contactData, onContactHighlight }) {
  const { upsertCustomer } = useCustomers();
  const isMobile = window.innerWidth < process.env.REACT_APP_MOBILE_SIZE;

  const [expanded, setExpanded] = React.useState(false);

  const [editName, setEditName] = React.useState(contactData.name);
  const [editPhoneNumber, setEditPhoneNumber] = React.useState(
    contactData.phoneNumber
  );
  const [editDog, setEditDog] = React.useState(contactData.dog);

  const handleCustomerSave = async () => {
    const requestBody = {
      Id: contactData.id,
      Name: editName,
      PhoneNumber: editPhoneNumber,
      Dog: editDog,
      Email: contactData.email,
    };

    try {
      await upsertCustomer(requestBody, "POST");
    } catch (error) {
      console.error(error);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        minWidth: "100%",
        backgroundColor: "",
        mb: 1,
        "&:hover": { cursor: "pointer" },
      }}
    >
      <CardContent
        sx={{
          p: "16px !important",
          display: "flex",
          justifyContent: "space-between",
        }}
        onClick={() => {
          if (!isMobile) {
            onContactHighlight(contactData);
          } else {
            // setSmallDetailsExpanded(!smallDetailsExpanded);
          }
        }}
      >
        <Box>
          <Box>
            <Typography variant="h5" component="div" sx={{ pr: 4 }}>
              {contactData.name}
            </Typography>
          </Box>

          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {contactData.dog !== null && contactData.dog !== undefined
              ? contactData.dog + " |"
              : ""}{" "}
            Liczba wizyt: {contactData.visitCount}
          </Typography>
        </Box>
        <Box>
          {isMobile ? (
            <Box>
              <CardActions disableSpacing>
                <IconButton
                  sx={{
                    transform: !expanded ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                  onClick={handleExpandClick}
                  aria-label="expand"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </CardContent>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ "& > *": { my: "8px !important" } }}>
          <Divider />

          <TextField
            fullWidth
            id="name"
            label="Imię i nazwisko"
            variant="outlined"
            value={editName ?? ""}
            sx={{ borderRadius: "8px" }}
            onChange={(e) => setEditName(e.target.value)}
          />
          <TextField
            fullWidth
            id="phoneNumber"
            label="Numer telefonu"
            variant="outlined"
            value={editPhoneNumber ?? ""}
            sx={{ borderRadius: "8px" }}
            onChange={(e) => setEditPhoneNumber(e.target.value)}
          />
          <TextField
            fullWidth
            id="dog"
            label="Pies"
            variant="outlined"
            value={editDog ?? ""}
            sx={{ borderRadius: "8px" }}
            onChange={(e) => setEditDog(e.target.value)}
          />

          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              fullWidth
              onClick={() => {
                setExpanded(!expanded);
                if (!expanded) {
                  setEditName(contactData.name);
                  setEditPhoneNumber(contactData.phoneNumber);
                  setEditDog(contactData.dog);
                }
              }}
            >
              Porzuć
            </Button>
            <Button
              variant="contained"
              endIcon={<SaveIcon />}
              onClick={handleCustomerSave}
              fullWidth
            >
              Zapisz
            </Button>
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}
