import * as React from "react";
import {
  Card,
  Collapse,
  CardContent,
  Typography,
  Box,
  TextField,
  Divider,
  Stack,
  Button,
  CardActions,
  IconButton,
} from "@mui/material";

import { useProducts } from "../../../Api/ServiceItemApi";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

export default function ServiceListItem({ product, onProductHighlight }) {
  const { upsertProduct } = useProducts();
  const isMobile = window.innerWidth < process.env.REACT_APP_MOBILE_SIZE;

  const [smallDetailsExpanded, setSmallDetailsExpanded] = React.useState(false);

  const [editName, setEditName] = React.useState("");
  const [editPrice, setEditPrice] = React.useState(0);
  const [editTime, setEditTime] = React.useState(0);

  const handleSave = async () => {
    const requestBody = {
      Id: product.id,
      Name: editName,
      Price: editPrice,
      Time: editTime,
    };

    try {
      await upsertProduct(requestBody, "POST");
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    setEditName(product.name);
    setEditPrice(product.price);
    setEditTime(product.time);
  }, [product]);

  return (
    <Card
      sx={{
        minWidth: "100%",
        backgroundColor: "",
        mb: 1,
        "&:hover": { cursor: "pointer" },
      }}
    >
      <CardContent
        sx={{
          p: "16px !important",
          display: "flex",
          justifyContent: "space-between",
        }}
        onClick={() => {
          if (!isMobile) {
            onProductHighlight(product);
          } else {
            setSmallDetailsExpanded(!smallDetailsExpanded);
          }
        }}
      >
        <Box>
          <Box>
            <Typography variant="h5" component="div" sx={{ pr: 4 }}>
              {product.name}
            </Typography>
          </Box>

          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            Czas: {product.time / 60} h | Cena: {product.price / 100} PLN
          </Typography>
        </Box>
        <Box>
          {isMobile ? (
            <Box>
              <CardActions disableSpacing>
                <IconButton
                  sx={{
                    transform: !smallDetailsExpanded
                      ? "rotate(0deg)"
                      : "rotate(180deg)",
                  }}
                  aria-label="expand"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </CardContent>

      <Collapse in={smallDetailsExpanded} timeout="auto" unmountOnExit>
        <Divider />

        <CardContent sx={{ "& > *": { my: "8px !important" } }}>
          <TextField
            fullWidth
            id="name"
            label="Nazwa"
            variant="outlined"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
          />
          <TextField
            fullWidth
            id="time"
            label="Czas"
            variant="outlined"
            value={editTime / 60}
            onChange={(e) => setEditTime(e.target.value * 60)}
          />
          <TextField
            fullWidth
            id="price"
            label="Cena"
            variant="outlined"
            value={editPrice / 100}
            onChange={(e) => setEditPrice(e.target.value * 100)}
          />

          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              fullWidth
              onClick={() => setSmallDetailsExpanded(false)}
            >
              Porzuć
            </Button>
            <Button
              variant="contained"
              endIcon={<SaveIcon />}
              fullWidth
              onClick={handleSave}
            >
              Zapisz
            </Button>
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}
