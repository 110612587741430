import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const exampleData = [
  { name: "Styczeń", uv: 4 },
  { name: "Luty", uv: 3 },
  { name: "Marczec", uv: 4 },
  { name: "Kwiecień", uv: 2 },
  { name: "Maj", uv: 1 },
  { name: "Czerwiec", uv: 3 },
];

const weekday = [
  "Poniedziałek",
  "Wtorek",
  "Środa",
  "Czwartek",
  "Piątek",
  "Sobota",
  "Niedziela",
];

export default function TinyRaportBarChart({ raportingData, dataType }) {
  const [displayData, setDisplayData] = React.useState(exampleData);

  // TO DO: create custom hook out of it as its being used twice
  // console.log(raportingData);
  React.useEffect(() => {
    if (raportingData !== undefined && raportingData.length !== 0)
    {
      
      if (raportingData.data.length !== 0) {
        var transformedArray = [];
        if (Object.keys(raportingData.data).includes("confirmedIncome")) {
          transformedArray = raportingData.data.confirmedIncome.map(
            (element, index) => {
              return {
                name: String(raportingData.labels[index]),
                należności: raportingData.data.unconfirmedIncome[index],
                opłacone: raportingData.data.confirmedIncome[index],
              };
            }
          );
        }

        if (Object.keys(raportingData.data).includes("workedHours")) {
          transformedArray = raportingData.data.workedHours.map(
            (element, index) => {
              return {
                name: String(raportingData.labels[index]),
                godziny: raportingData.data.workedHours[index],
              };
            }
          );
        }
  
        setDisplayData(transformedArray);
      }
    }
  }, [raportingData]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={displayData}>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        {dataType === "income" ? (
          <>
          <Bar
          dataKey="należności"
          fill="#d97706"
          activeBar={<Rectangle fill="#d97706" />}
        />
        <Bar
          dataKey="opłacone"
          fill="#d97706"
          activeBar={<Rectangle fill="#d97706" />}
        /></>
        ) : ''}
        {dataType === "workedHours" ? (
          <Bar
          dataKey="godziny"
          fill="#d97706"
          activeBar={<Rectangle fill="#d97706" />}
        />
        ) : ''}
      </BarChart>
    </ResponsiveContainer>
  );
}
