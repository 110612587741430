import * as React from "react";
import {
  Typography,
  Box,
  TextField,
  Stack,
  Collapse,
  Button,
} from "@mui/material";

import { ButtonWithDisabledByRole } from "../../ButtonDisabledByRole/withDisabledByRole";
import { useUpsertCustomer } from "../../../Api/useUpsertCustomer";

import PersonAddIcon from "@mui/icons-material/PersonAdd";

const backgroundColor = "#737373";
const formBackgroundColor = "#a3a3a3";

export default function ContactCard({ handleCustomerUpsert }) {
  const { upsertCustomer, loading } = useUpsertCustomer();

  const isMobile = window.innerWidth < 450;

  const [editMode, setEditMode] = React.useState(false);
  const [editName, setEditName] = React.useState("");
  const [editDog, setEditDog] = React.useState("");
  const [editPhoneNumber, setEditPhoneNumber] = React.useState("");

  const handleSave = async () => {
    const requestBody = {
      // Id: contactData.id,
      name: editName,
      phoneNumber: editPhoneNumber,
      dog: editDog,
    };

    try {
      await upsertCustomer(requestBody, "POST");

      setEditName("");
      setEditPhoneNumber("");
      setEditDog("");

      handleCustomerUpsert(requestBody);
    } catch (error) {
      console.error(error);
    }
  };

  if (!isMobile) {
    return (
      <Box>
        <Box
          sx={{
            backgroundColor: backgroundColor,
            height: 48,
            width: "100% !important",
            p: 1,
            display: "flex",
            justifyContent: "space-between",
            m: 0,
          }}
        >
          <Typography variant="h6">Kontakty</Typography>
          <Button
            variant="contained"
            color={editMode ? "error" : "success"}
            onClick={() => setEditMode(!editMode)}
            size="small"
            sx={{ height: "32px" }}
          >
            {editMode ? "Anuluj" : "Dodaj nowego klienta"}
            <PersonAddIcon sx={{ ml: 1 }} />
          </Button>
        </Box>

        <Collapse in={editMode} timeout="auto" unmountOnExit>
          <Box
            sx={{
              backgroundColor: formBackgroundColor,
              height: 240,
              width: "100% !important",
              p: 1,
              display: "flex",
              justifyContent: "space-between",
              m: 0,
            }}
          >
            <Stack spacing={1} width={1}>
              <TextField
                id="name"
                label="Imię i nazwisko"
                variant="outlined"
                sx={{ width: 1 / 2 }}
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                required
              />
              <TextField
                id="phone"
                label="Telefon"
                variant="outlined"
                sx={{ width: 1 / 2 }}
                value={editPhoneNumber}
                onChange={(e) => setEditPhoneNumber(e.target.value)}
                required
              />
              <TextField
                id="dog"
                label="Pies"
                variant="outlined"
                sx={{ width: 1 / 2 }}
                value={editDog}
                onChange={(e) => setEditDog(e.target.value)}
                required
              />
              <ButtonWithDisabledByRole
                variant="contained"
                color="primary"
                onClick={() => {
                  // setEditMode(!editMode);
                  handleSave();
                }}
                size="small"
                disabled={loading}
                sx={{ height: "32px", width: 1 / 2 }}
              >
                {loading ? "Zapisywanie..." : "Zapisz"}
                <PersonAddIcon sx={{ ml: 1 }} />
              </ButtonWithDisabledByRole>
            </Stack>
          </Box>
        </Collapse>
      </Box>
    );
  }
}
