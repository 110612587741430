import React, { useState } from "react";
import {
  Collapse,
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  Stack,
  TextField,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DividerWithText = ({ handleExpandHistory, isOpened }) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", my: 2, color: "black" }}
      onClick={handleExpandHistory}
    >
      <Divider sx={{ flex: 1, borderColor: "black" }} />
      <Typography
        sx={{ ml: 1, color: "black" }}
        variant="body1"
        color="textSecondary"
      >
        {isOpened ? "Schowaj" : "Pokaż"} hisotirę wizyt
      </Typography>
      <ExpandMoreIcon
        sx={{
          transform: isOpened ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.3s",
        }}
      />
      <Divider sx={{ flex: 1, borderColor: "black" }} />
    </Box>
  );
};

export default function ContactVisitHistory({ customerVisits }) {
  const [expandHistory, setExpandHistory] = useState(false);

  const handleExpandHistory = async () => {
    setExpandHistory(!expandHistory);
  };

  return (
    <Box>
      <DividerWithText
        handleExpandHistory={handleExpandHistory}
        isOpened={expandHistory}
      />

      <Collapse in={expandHistory}>
        {customerVisits.map((visit) => (
          <Card sx={{ my: 1 }} key={visit.id}>
            <CardContent>
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mb: 0 }}
                >
                  <Typography component={"div"} variant="h6">
                    {visit.title}
                  </Typography>
                  <Typography component={"div"} variant="h6">
                    {visit.price / 100} PLM
                  </Typography>
                </Stack>
                <Typography
                  component={"div"}
                  variant="body1"
                  sx={{
                    mb: visit.comment !== null && visit.comment !== "" ? 2 : 0,
                  }}
                >
                  {visit.start}
                </Typography>
                {visit.comment !== null && visit.comment !== "" && (
                  <TextField
                    id="outlined-textarea"
                    label="Twoje notatki."
                    placeholder="Miejsce na twoją notatkę. Możesz tutaj wpisać coś o wizycie. To pole jest opcjonalne."
                    multiline
                    rows={3}
                    fullWidth
                    value={visit.comment}
                    disabled
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Collapse>
    </Box>
  );
}
