import React from "react";
import { Box, Typography } from "@mui/material";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const exampleData = [
  { name: "Styczeń", ilość: 4 },
  { name: "Luty", ilość: 3 },
  { name: "Marczec", ilość: 4 },
  { name: "Kwiecień", ilość: 2 },
  { name: "Maj", ilość: 1 },
  { name: "Czerwiec", ilość: 3 },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          borderRadius: 2,
          padding: 2,
          backgroundColor: "grey",
        }}
      >
        {payload.map((item, key) => (
          <Box display={"flex"} alignItems={"center"} key={key}>
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: item.color,
                mr: 1,
                borderRadius: 1,
              }}
            ></Box>
            <Typography className="label">{`${label} : ${item.value}`}</Typography>
          </Box>
        ))}
      </Box>
    );
  }

  return null;
};

export default function RaportAreaChart({ raportingData, labels }) {
  const [displayData, setDisplayData] = React.useState(raportingData);

  // TO DO: when parent component does not pass prop, it breaks
  React.useEffect(() => {
    if (raportingData !== undefined) {
      if (Object.keys(raportingData).length !== 0) {
        const transformedArray = Object.keys(raportingData).map((day) => {
          return {
            name: String(labels[day]),
            ilość: raportingData[day].length,
          };
        });
  
        setDisplayData(transformedArray);
      } else {
        setDisplayData(exampleData);
      }
    }
  }, [raportingData, labels]);

  return (
    <ResponsiveContainer width="100%">
      <AreaChart
        width={0}
        height={0}
        data={displayData}
        margin={{
          right: 30,
        }}
        sx={{ color: "white" }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#d97706" stopOpacity={0.7} />
            <stop offset="95%" stopColor="#d97706" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 9" vertical={false} />
        <XAxis
          dataKey="name"
          stroke={"silver"}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 3) + "."}
        />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="ilość"
          fillOpacity={1}
          fill="url(#colorUv)"
          stroke="#d97706"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
