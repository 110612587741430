import React from "react";
import {
  Container,
  Card,
  Box,
  CircularProgress,
  Grid,
  Button,
  ButtonGroup,
  Typography,
} from "@mui/material";

import { useGetRaportData } from "../Api/useGetRaportData";
import { getTodaysDateDMY } from "../utils/dateutils";

import RaportAreaChartWrapper from "../components/Charts/RaportAreaChart/RaportAreaChartWrapper";
import RaportBarChartWrapper from "../components/Charts/RaportBarChart/RaportBarChartWrapper";

export default function RaportsPage() {
  const isMobile = window.innerWidth < process.env.REACT_APP_MOBILE_SIZE;
  const backgroundColor = isMobile ? "" : "#44403c";
  const color = isMobile ? "" : "white";

  // filters controls
  const [todaysDate, setTodaysDate] = React.useState(getTodaysDateDMY());
  const [timeInterval, setTimeInterval] = React.useState("week");
  // end of filter controls

  const {
    entities: workedHours,
    loading: loadingWorkedHours,
    fetchRaportingData: fetchWorkedHours,
  } = useGetRaportData([], "GetWorkedHoursDynamicRange", todaysDate);

  const {
    entities: weekIncome,
    loading: loadingWeekIncome,
    fetchRaportingData: fetchWeekIncome,
  } = useGetRaportData([], "GetIncomeDynamicRange", todaysDate);

  const {
    entities: weekVisits,
    loading: loadingWeekVisits,
    fetchRaportingData: fetchWeekVisits,
  } = useGetRaportData([], "GetVisitsDynamicRange", todaysDate);

  const handleWeekChange = (todaysDate, dataType) => {
    if (dataType === "workedHours") {
      fetchWorkedHours("GetWorkedHoursDynamicRange", todaysDate, timeInterval);
    } else if (dataType === "income") {
      fetchWeekIncome("GetIncomeDynamicRange", todaysDate, timeInterval);
    } else if (dataType === "visits") {
      fetchWeekVisits("GetVisitsDynamicRange", todaysDate, timeInterval);
    } else {
      console.log("Not handled case.");
    }
  };

  const handleTimeIntervalChange = (newTimeInterval) => {
    // refetching entities for new time interval
    fetchWorkedHours("GetWorkedHoursDynamicRange", todaysDate, newTimeInterval);
    fetchWeekIncome("GetIncomeDynamicRange", todaysDate, newTimeInterval);
    fetchWeekVisits("GetVisitsDynamicRange", todaysDate, newTimeInterval);

    setTimeInterval(newTimeInterval);
  };

  return (
    <Grid container>
      <Grid sm={3} item>
        <Card
          sx={{
            backgroundColor: backgroundColor,
            color: color,
            mt: 4,
            ml: 2,
            p: 1,
            position: "sticky",
            top: "6rem",
          }}
        >
          <Box>
            <Typography variant="h6">Pokazuj dane zgrupowane dla:</Typography>
            <ButtonGroup
              variant="contained"
              aria-label="Basic button group"
              size="small"
              fullWidth
            >
              <Button
                variant={timeInterval === "week" ? "contained" : "outlined"}
                onClick={() => handleTimeIntervalChange("week")}
              >
                Tydzień
              </Button>
              <Button
                variant={timeInterval === "month" ? "contained" : "outlined"}
                onClick={() => handleTimeIntervalChange("month")}
              >
                Miesiąc
              </Button>
              <Button
                variant={timeInterval === "year" ? "contained" : "outlined"}
                onClick={() => handleTimeIntervalChange("year")}
              >
                Rok
              </Button>
            </ButtonGroup>
          </Box>
          <Box>
            <Typography variant="h6">Powrót do:</Typography>
            <Button
              size="small"
              fullWidth
              variant={"contained"}
              onClick={() => {
                  handleTimeIntervalChange("week");
                  setTodaysDate(getTodaysDateDMY());

                  // this is not the best solution for this issue I guess
                  // the issue - when you click 'Dzisiaj', timeIntervalNames are not being reset
                  // inside raport chart wrappers
                  window.location.reload();
              }}
            >
              Dzisiaj
            </Button>
          </Box>
        </Card>
      </Grid>
      <Grid sm={9} item>
        <Container
          sx={{
            maxWidth: "100% !important",
            ml: { md: 0, lg: 0 },
            pt: 4,
            pb: 4,
          }}
        >
          <Box position="relative">
            {loadingWeekVisits && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  zIndex: 10,
                }}
              >
                <CircularProgress size={140} thickness={5} />
              </Box>
            )}
            <Card sx={{ backgroundColor: backgroundColor, color: color }}>
              <RaportAreaChartWrapper
                data={weekVisits}
                dataType={"visits"}
                timeInterval={timeInterval}
                propagateWeekChange={handleWeekChange}
              />
            </Card>
          </Box>

          <Box position="relative">
            {loadingWeekIncome && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  zIndex: 10,
                }}
              >
                <CircularProgress size={140} thickness={5} />
              </Box>
            )}
            <Card
              sx={{ backgroundColor: backgroundColor, color: color, mt: 4 }}
            >
              <RaportBarChartWrapper
                title="Należności"
                propagateWeekChange={handleWeekChange}
                data={weekIncome}
                dataType="income"
                timeInterval={timeInterval}
              />
            </Card>
          </Box>

          <Box position="relative">
            {loadingWorkedHours && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  zIndex: 10,
                }}
              >
                <CircularProgress size={140} thickness={5} />
              </Box>
            )}
            <Card
              sx={{ backgroundColor: backgroundColor, color: color, mt: 4 }}
            >
              <RaportBarChartWrapper
                title="Przepracowane godziny"
                dataType="workedHours"
                propagateWeekChange={handleWeekChange}
                data={workedHours}
                timeInterval={timeInterval}
              />
            </Card>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
}
